import { postTrans } from "@/api";
export default {
  data() {
    return {
      chosePayItem: 0,
      money: "",
      phone: "",
      accountType: "666"
    };
  },
  methods: {
    chosePay(value) {
      this.chosePayItem = value;
    },
    onConfirm() {
      if (!this.accountType) {
        this.$toast("请选择账户种类");
        return;
      }
      if (!this.money) {
        this.$toast("请输入金额，最少100");
        return;
      }
      if (!this.phone) {
        this.$toast("请输入对方账户手机号");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      let data = {
        type: this.accountType,
        phone: this.phone,
        money: this.money
      };
      postTrans({
        ...data
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$store.dispatch("getUserInfo");
        this.show = false;
        this.password = "";
        this.num = "";
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};